
import { defineComponent, onMounted } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

export default defineComponent({
  setup() {
    const store = useStore();
    const router = useRouter();

    onMounted(() => {
      store.dispatch('fetchMeta').then((res: any) => {
        switch (res.type) {
          case 'company':
            router.replace({ name: 'OverviewCompany' });
            break;
          case 'unit':
            router.replace({ name: 'OverviewUnit', params: { id: res.id } });
            break;
          case 'personal':
            router.replace({ name: 'OverviewPersonal', params: { id: res.id } });
            break;
        }
      });
    });
  },
});
