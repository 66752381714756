
interface UserRecord {
  id: number;
  email: string;
  username: string;
  firstname: string;
  lastname: string;
  unit_id: string;

  state: string;
}

import { computed, defineComponent, onMounted, PropType, ref } from 'vue';
import { useStore } from 'vuex';
import { mdiPlus, mdiCancel } from '@mdi/js';
import { SkillRecord } from '@/views/app/skills_new/Personal.vue';

export interface OpenFeedbackResult {
  user: UserRecord;
  unit: UnitRecord;
  skills: Array<SkillRecord>;
}

interface UnitRecord {
  id: number;
  name: string;
}

type Grade = {
  skill: number;
  grade: number;
};

export default defineComponent({
  props: {
    feedback: { type: Object as PropType<OpenFeedbackResult>, required: true },
    index: { type: Number, default: 0 },
    self: { type: Boolean },
  },
  emits: ['save'],
  setup(props, { emit }) {
    const store = useStore();
    const grades = ref<Array<Grade>>([]);

    const save = () => {
      store.dispatch('saveFeedback', { id: props.feedback.user.id, grades: grades.value }).then(() => {
        emit('save');
      });
    };

    const sortedSkills = computed(() => [...props.feedback.skills].sort(store.getters.getSort()));

    const isActive = (skillid: number, grade: number) => {
      return undefined != grades.value.find((graderecord: Grade) => graderecord.skill == skillid && graderecord.grade == grade);
    };

    const gradeSkill = (skill: number, grade: number) => {
      const graderecord = grades.value.find((grade: Grade) => grade.skill == skill);
      if (!graderecord) {
        grades.value.push({
          skill,
          grade,
        });
      } else {
        grades.value = grades.value.map((gradeitem: Grade) => {
          if (gradeitem.skill == skill) {
            return {
              skill,
              grade,
            };
          }

          return gradeitem;
        });
      }
    };

    const open = ref(false);
    const toggleOpen = () => {
      open.value = !open.value;
    };

    onMounted(() => {
      open.value = props.self;
    });

    return {
      sortedSkills,
      open,
      toggleOpen,
      isActive,
      gradeSkill,
      save,
      mdiPlus,
      mdiCancel,
    };
  },
});
