import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "container",
  id: "pagecontainer"
}
const _hoisted_2 = { class: "legal" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ModalButton = _resolveComponent("ModalButton")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Icon = _resolveComponent("Icon")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_ModalButton, { link: "impress" }, {
          default: _withCtx(() => [
            _createTextVNode("Impressum")
          ]),
          _: 1
        }),
        _createVNode(_component_ModalButton, { link: "privacy" }, {
          default: _withCtx(() => [
            _createTextVNode("Datenschutzerklärung")
          ]),
          _: 1
        })
      ]),
      _createVNode(_component_router_view)
    ]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.topVisible)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              id: "backToTop",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.scrollToTop && _ctx.scrollToTop(...args)))
            }, [
              _createVNode(_component_Icon, { path: _ctx.mdiChevronUp }, null, 8, ["path"])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    })
  ], 64))
}